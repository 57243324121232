@import './font.scss';

body {
    margin: 0;
    padding: 0;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}

html {
    font-size: 16px;    
}

html::-webkit-scrollbar {
    width: 6.4px;
}

html::-webkit-scrollbar-thumb {
    //using colors.grey
    background: #cccccc;
    border-radius: 4px;
}
