.hnp-login-container {
    background-color: #010101 !important;
    background: url('../../assets/images/login-page.png') no-repeat right center; 
    background-size: 70% 100%;
    @media only screen and (max-width: 960px) {
        background-position: left center ;
        background-size: auto;  
    }
    @media only screen and (max-width: 600px) {
        background-position: left 5rem bottom ;
        background-size: cover;  
    }
}
