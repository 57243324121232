$font-host: 'https://cdn.hcltechsw.com/public/';
$font-primary: 'Open Sans';
$font-secondary: 'HCLTech Roobert';

@mixin baseFontFace($fontFamily, $url1: null, $url2: null, $weight: null) {
  font-family: $fontFamily ;
  @if ($url1 and $url2) {
    src: url($font-host + $url1) format('woff2'),
      url($font-host + $url2) format('woff');
  } @else if($url1) {
    src: url($font-host + $url1);
  } @else if($url2) {
    src: url($font-host + $url2);
  }
  @if ($weight) {
    font-weight: $weight;
  }
}

@font-face {
    @include baseFontFace(
      $font-primary,
      'opensans-regular_400-webfont.woff2',
      null,
      400
    );
  }

  @font-face {
    @include baseFontFace(
      $font-primary,
      'opensans-semibold_600-webfont.woff2',
      null,
      600
    );
  }

@font-face {
  @include baseFontFace(
    $font-secondary,
    'HCLTechRoobert-Regular.woff2',
    null,
    400
  );
}

@font-face {
    @include baseFontFace(
      $font-secondary,
      'HCLTechRoobert-Medium.woff2',
      null,
      500
    );
  }

@font-face {
    @include baseFontFace(
      $font-secondary,
      'HCLTechRoobert-Bold.woff2',
      null,
      700
    );
  }
